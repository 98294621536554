import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [addresses, setAddresses] = useState([]);
  const [totalStartingBalance, setTotalStartingBalance] = useState(0);
  const [totalDifference, setTotalDifference] = useState(0);
  const [coinsAreMoving, setCoinsAreMoving] = useState(false);
  const [krakenBalancesChanged, setKrakenBalancesChanged] = useState(false);
  const [bitgoBalancesChanged, setBitgoBalancesChanged] = useState(false);
  const [krakenUpdateTimestamp, setKrakenUpdateTimestamp] = useState('');
  const [bitgoUpdateTimestamp, setBitgoUpdateTimestamp] = useState('');
  const [totalCurrentBalance, setTotalCurrentBalance] = useState(0);
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = Math.floor(Date.now() / 60000); // Get the current timestamp in minutes
        const response = await fetch(`https://r2.goxxed.org/address_info.json?t=${timestamp}`); // Append the timestamp to the URL
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setAddresses(data);
        // Calculate totals
        const totalStarting = data.reduce((acc, address) => acc + address.startingBalance, 0);
        const totalCurrent = data.reduce((acc, address) => acc + address.currentBalance, 0);
        const totalMoved = data.reduce((acc, address) => acc + address.difference, 0);
        const coinsMoving = data.some(address => address.difference !== 0);
        setTotalStartingBalance(totalStarting);
        setTotalDifference(totalMoved);
        setTotalCurrentBalance(totalCurrent);
        setCoinsAreMoving(coinsMoving);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const fetchKrakenData = async () => {
      try {
        const timestamp = Math.floor(Date.now() / 60000); // Get the current timestamp in minutes
        const response = await fetch(`https://r2.goxxed.org/kraken.json?t=${timestamp}`); // Append the timestamp to the URL
        if (!response.ok) {
          throw new Error('Failed to fetch Kraken data');
        }
        const data = await response.json();
        setKrakenBalancesChanged(data.kraken_balances_changed);
        setKrakenUpdateTimestamp(data.timestamp);
      } catch (error) {
        console.error('Error fetching Kraken data:', error);
      }
    };
    
    const fetchBitgoData = async () => {
      try {
        const timestamp = Math.floor(Date.now() / 60000); // Get the current timestamp in minutes
        const response = await fetch(`https://r2.goxxed.org/bitgo.json?t=${timestamp}`); // Append the timestamp to the URL
        if (!response.ok) {
          throw new Error('Failed to fetch Bitgo data');
        }
        const data = await response.json();
        setBitgoBalancesChanged(data.bitgo_balances_changed);
        setBitgoUpdateTimestamp(data.timestamp);
      } catch (error) {
        console.error('Error fetching Bitgo data:', error);
      }
    };
    const fetchLastUpdated = async () => {
      try {
        const timestamp = Math.floor(Date.now() / 60000); // Get the current timestamp in minutes
        const response = await fetch(`https://r2.goxxed.org/last_updated.json?t=${timestamp}`); // Append the timestamp to the URL
        if (!response.ok) {
          throw new Error('Failed to fetch last updated data');
        }
        const data = await response.json();
        setLastUpdated(data.lastUpdated);
      } catch (error) {
        console.error('Error fetching last updated data:', error);
      }
    };

    fetchData();
    fetchKrakenData();
    fetchBitgoData();
    fetchLastUpdated();
  }, []);

  // Sort addresses based on difference, non-zero values at top, zeros at bottom
  const sortedAddresses = [...addresses].sort((a, b) => {
    if (a.difference === 0 && b.difference !== 0) return 1;
    if (a.difference !== 0 && b.difference === 0) return -1;
    if (a.difference !== 0 && b.difference !== 0) return a.difference - b.difference;
    return 0;
  });

  const getTimeSinceUpdate = (updateTimestamp) => {
    const secondsSinceUpdate = Math.floor((new Date() - new Date(updateTimestamp)) / 1000);
    
    if (secondsSinceUpdate < 60) {
      return `${secondsSinceUpdate} second${secondsSinceUpdate !== 1 ? 's' : ''} ago`;
    } else if (secondsSinceUpdate < 3600) {
      const minutesSinceUpdate = Math.floor(secondsSinceUpdate / 60);
      return `${minutesSinceUpdate} minute${minutesSinceUpdate !== 1 ? 's' : ''} ago`;
    } else {
      const hoursSinceUpdate = Math.floor(secondsSinceUpdate / 3600);
      return `${hoursSinceUpdate} hour${hoursSinceUpdate !== 1 ? 's' : ''} ago`;
    }
  };

  return (
<div className="App">
<header>
  <h1 style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
    MtGox Wallet Status<br />
    <span className="status" style={{ color: coinsAreMoving ? 'red' : 'green', fontWeight: 'bold' }}>
      {coinsAreMoving ? 'COINS ARE MOVING' : 'Balances Unchanged'}
    </span>
    <br />
    <span className="last-updated" style={{ fontSize: '14px', color: '#666' }}>
      Last Checked: {lastUpdated ? getTimeSinceUpdate(lastUpdated) : 'Loading...'}
    </span>
  </h1>
</header>

<div style={{ marginTop: '20px', marginBottom: '20px', fontSize: '16px', fontWeight: 'bold' }}>
  <div style={{ marginBottom: '10px' }}>Updates</div>
  <div style={{ marginBottom: '10px' }}>23rd July: MtGox transfer to BitStamp. Customers waiting for funds.</div>
  <div style={{ marginBottom: '10px' }}>23rd July: Kraken distribution to customers has been completed. 47,228~ BTC</div>
  <div>The website and bot have been updated to track the remaining wallets.</div>
</div>

<section className="totals">
  <div className="total-item">
    <span className="label">Starting Balance: </span>
    <span className="value">{totalStartingBalance.toFixed(8)} BTC</span>
  </div>
  <div className="total-item">
    <span className="label">Current Balance: </span>
    <span className="value">{totalCurrentBalance.toFixed(8)} BTC</span>
  </div>
  <div className="total-item">
    <span className="label">Coins Moved: </span>
    <span className="value">{totalDifference.toFixed(8)} BTC</span>
  </div>
</section>

  <section className="additional-info">
  <h2>
    <abbr data-title="Checking volunteer account balances for new deposits. Disclaimer: This may be a false positive, check additional sources for confirmation. (Reddit, Twitter, Telegram)">
      Customer Account Balances
    </abbr>
  </h2>
  <div className="info-item">
    <div className="logo-container">
      <img src="kraken-logo.svg" alt="Kraken Logo" className="logo" />
    </div>
    <div className="value-container">
      {/* <span className="value">
        {krakenBalancesChanged ? 'BTC Deposited' : 'No Change'} - Update:{' '}
        {getTimeSinceUpdate(krakenUpdateTimestamp)}
        {new Date() - new Date(krakenUpdateTimestamp) > 12 * 60 * 60 * 1000 && (
          <span className="stale-data"> - STALE DATA</span>
        )}
      </span> */}
      <span className="value" style={{ color: 'green', fontWeight: 'bold' }}>
        Customers received funds on 23th July 2024
     </span>
    </div>
  </div>
  <div className="info-item">
    <div className="logo-container">
      <img src="bitgo-logo.svg" alt="Bitgo Logo" className="logo" />
    </div>
    <div className="value-container">
      <span className="value">
        {bitgoBalancesChanged ? 'BTC Deposited' : 'No Change'} - Update: {' '}
        {getTimeSinceUpdate(bitgoUpdateTimestamp)}
        {new Date() - new Date(bitgoUpdateTimestamp) > 12 * 60 * 60 * 1000 && (
          <span className="stale-data"> - STALE DATA</span>
        )}
      </span>
    </div>
  </div>
  <div className="subscribe-link">
    <a href="https://t.me/+tAz4rMqniAYwYjc0" target="_blank" rel="noopener noreferrer">
      <div className="subscribe-box">
        <img src="/telegram_logo.svg" alt="Telegram Logo" className="telegram-logo" />
        <span>Subscribe to Telegram Notifications</span>
      </div>
    </a>
  </div>
</section>
  {sortedAddresses.length > 0 ? (
  <section className="table-container">
    <h3>MtGox Addresses</h3>
  <table className="responsive-table">
    <thead>
      <tr>
        <th>Address</th>
        <th>Starting Balance</th>
        <th>Current Balance</th>
        <th>Change</th>
      </tr>
    </thead>
    <tbody>
      {sortedAddresses.map((address, index) => (
        <tr
          key={index}
          className={
            address.difference < 0
              ? 'red-background'
              : address.difference > 0
              ? 'green-background'
              : 'default-background'
          }
        >
          <td data-label="Address">{address.address}</td>
          <td data-label="Starting Balance">{address.startingBalance}</td>
          <td data-label="Current Balance">{address.currentBalance}</td>
          <td data-label="Change">{address.difference}</td>
        </tr>
      ))}
    </tbody>
  </table>
</section>
  ) : (
    <p>No data available.</p>
  )}
</div>
  );
}

export default App;